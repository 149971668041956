<template>
  <div>
    <section class="single-service">
      <div class="container">
        <div class="row">
          <div
            class="sidebar sidebar-services col-xs-12 col-sm-12 col-md-3"
          ></div>
          <!-- .sidebar end -->
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div
              class="page-title title-1 text-center"
              style="
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              "
            >
              <ol class="breadcrumb">
                <li>
                  <router-link to="/">Home</router-link>
                </li>
                <li>
                  <router-link to="/Services">Services</router-link>
                </li>
                <li class="active">Single Service</li>
              </ol>
            </div>
            <br /><br /><br />
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12" v-if="services">
                <div
                  style="
                    width: 100%;
                    height: 60vh;
                    background-size: cover;
                    background-position: center center;
                  "
                  :style="
                    `background-image: url(${
                      $hostname + services.imageLink.replaceAll('\\', '/')
                    }` + ')'
                  "
                ></div>
                <br />
                <div class="service-title">
                  <h3>{{ services.title }}</h3>
                </div>
                <div class="service-content">
                  <p v-html="services.description"></p>
                </div>
              </div>
              <!-- .col-md-12 end -->
              <div class="col-xs-12 col-sm-12 col-md-12">
                <div class="service-title">
                  <h3>{{ services.title2 }}</h3>
                </div>
                <div class="service-content">
                  <p v-html="services.body"></p>
                </div>
              </div>
            </div>
            <!-- .row end -->
          </div>
          <!-- .col-md-9 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Call To Action #1
============================================= -->
  
  </div>
</template>



<script>
import $ from "jquery";
export default {
  name: "SingleService",

  data() {
    return {
      services: null,
    };
  },
  methods: {
    cleanDate(date) {
      let thisDate = new Date(date);
      return thisDate.toLocaleDateString("en-GB");
    },
    getservices() {
      this.$loading(true);

      this.$Axios
        .get(`${this.$hostname}/api/Services/${this.$route.params.id}`)
        .then((Response) => {
          this.services = Response.data;
          this.$loading(false);
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },
  },
  mounted() {
    this.getservices();
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      $(".navbar-collapse").removeClass("in");
    }, 100);
  },
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"myModal","tabindex":"-1","role":"dialog","aria-labelledby":"myModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-center"},[_c('div',{staticClass:"modal-content"},[(_vm.imagean)?_c('div',{},[(_vm.imagean)?_c('carousel',{staticStyle:{"position":"relative"},attrs:{"dots":false,"data-toggle":"modal","href":"#myModal","responsive":{
              0: { items: 1, nav: false },
              600: { items: 1, nav: false },
            }}},_vm._l((_vm.imagean),function(i){return _c('div',{key:i.id,staticStyle:{"width":"100%","height":"50vh","background-size":"contain","background-position":"center center","background-repeat":"no-repeat","background-color":"#2e2e2e"},style:(`background-image: url(${
                  _vm.$hostname + i.imageLink.replaceAll('\\', '/')
                }` + ')'),on:{"click":function($event){_vm.selectedImage = _vm.$hostname + i.imageLink.replaceAll('\\', '/')}}})}),0):_vm._e(),_vm._m(0)],1):_vm._e()])])]),_c('section',{staticClass:"bg-overlay bg-overlay-gradient pb-0"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"page-title title-1 text-center",staticStyle:{"padding-top":"0px !important","padding-bottom":"0px !important"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',[_c('router-link',{attrs:{"to":"/projects"}},[_vm._v("Projects")])],1),_c('li',{staticClass:"active"},[_vm._v("Single Project")])])])])])])]),_c('section',{staticClass:"single-project pb-0"},[_c('div',{staticClass:"container"},[(_vm.projects)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-4"},[_c('div',{staticClass:"project-widget project-block"},[_vm._m(1),_c('div',{staticClass:"project-desc"},[_c('ul',{staticClass:"list-unstyled"},[_c('li',[_vm._v(" Location: "),_c('span',[_vm._v(_vm._s(_vm.projects.location))])]),_c('li',[_vm._v(" Gross Building Area: "),_c('span',[_vm._v(_vm._s(_vm.projects.area))])]),_c('li',[_vm._v(" Category: "),_c('span',[_vm._v(_vm._s(_vm.projects.category))])])])])])]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-8"},[_c('div',{staticClass:"project-carousel mb-30",attrs:{"id":"project-carousel"}},[_c('div',{staticStyle:{"width":"100%","height":"40vh","background-size":"cover","background-position":"center center"},style:(`background-image: url(${
                    _vm.$hostname + _vm.projects.imageLink.replaceAll('\\', '/')
                  }` + ')')})]),_c('div',{staticClass:"container__img-holder"},[(_vm.imagean)?_c('carousel',{attrs:{"data-toggle":"modal","href":"#myModal","responsive":{
                  0: { items: 1, nav: false },
                  600: { items: 2, nav: false },
                },"stagePadding":"15","autoplay":"true"}},_vm._l((_vm.imagean),function(i){return _c('div',{key:i.id,staticStyle:{"width":"100%","height":"40vh","background-size":"cover","background-position":"center center","border-radius":"10px"},style:(`background-image: url(${
                      _vm.$hostname + i.imageLink.replaceAll('\\', '/')
                    }` + ')'),on:{"click":function($event){_vm.selectedImage =
                      _vm.$hostname + i.imageLink.replaceAll('\\', '/')}}})}),0):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[_c('div',{staticClass:"project-widget"},[_c('div',{staticClass:"project-title"},[_c('h3',[_vm._v(_vm._s(_vm.projects.title))])]),_c('div',{staticClass:"project-desc"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.projects.description)}})])])]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[_c('div',{staticClass:"project-widget"},[_c('div',{staticClass:"project-title"},[_c('h3',[_vm._v(_vm._s(_vm.projects.title2))])]),_c('div',{staticClass:"project-desc"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.projects.body)}})])])])])])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"absolute","top":"20px","right":"10px","border-radius":"10px","z-index":"100000000"}},[_c('button',{staticClass:"close",staticStyle:{"display":"block","margin":"auto"},attrs:{"type":"button","data-dismiss":"modal","aria-hidden":"true"}},[_c('span',{staticStyle:{"color":"white","font-weight":"bold"}},[_vm._v("X")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project-title"},[_c('h3',[_vm._v("Project Description")])])
}]

export { render, staticRenderFns }
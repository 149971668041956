<template>
  <div>
    <!-- Page Title
============================================= -->
    <section
      class="bg-overlay bg-overlay-gradient pb-0"
      style="padding-top: 0px !important"
    >
      <!-- <div class="bg-section">
        <img
         style="width: 100%;"
         :src="$hostname+'/Images/6.jpg'" alt="Background" />
      </div> -->
      
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div
              class="page-title title-1 text-center"
              style="
                padding-top: 50px !important;
                padding-bottom: 50px !important;
              "
            >
              <div class="title-bg">
                <h2>About Us</h2>
              </div>
              <ol class="breadcrumb">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li class="active">About</li>
              </ol>
            </div>
            <!-- .page-title end -->
          </div>
          <!-- .col-md-12 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Shortcode #1 
============================================= -->
    <section id="shotcode-1" class="shotcode-1 text-center-xs text-center-sm">
      <div class="container">
        <div class="row" v-for="a in About" :key="a.id">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <div class="heading heading-4">
                  <div class="heading-bg heading-right">
                    <p class="mb-0">All About Xera House</p>
                    <h2>Our Story</h2>
                  </div>
                </div>
                <!-- .heading end -->
              </div>
            </div>
          </div>
          <!-- .col-md-12 end -->
          <div class="col-xs-12 col-sm-12 col-md-6">
            <h3 class="color-heading mb-sm font-18">
              {{ a.title }}
            </h3>
            <p class="mb-60" v-html="a.description"></p>
          </div>
          <!-- .col-md-6 end -->
          <div class="col-xs-12 col-sm-12 col-md-6">
            <div
              style="
                width: 100%;
                height: 40vh;
                background-size: cover;
                background-position: center center;
              "
              :style="
                `background-image: url(${
                  $hostname + a.imageLink.replaceAll('\\', '/')
                }` + ')'
              "
            ></div>
          </div>
          <!-- .col-md-6 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Shortcode #6 
============================================= -->
    <section id="shortcode-6" class="shortcode-6 bg-gray text-center-xs">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="heading heading-4">
              <div class="heading-bg heading-right">
                <p class="mb-0">Internationally Trusted !</p>
                <h2>Certifications</h2>
              </div>
            </div>
            <!-- .heading end -->
          </div>
          <!-- .col-md-12 end -->
        </div>
        <!-- .row end -->
        <div class="row">
          <div
            class="col-xs-12 col-xs-12 col-md-12 mb-50"
            v-for="c in Certifications"
            :key="c.id"
          >
            <p v-html="c.description"></p>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-2 text-center-sm mb-30-sm">
            <div class="feature feature-2">
              <div class="feature-icon">
                <i class="lnr lnr-license font-40 color-theme"></i>
              </div>
              <h4 class="text-uppercase font-16">safety</h4>
            </div>
          </div>
          <!-- .col-md-6 end -->
          <div class="col-xs-12 col-sm-6 col-md-2 text-center-sm mb-30-sm">
            <div class="feature feature-2">
              <div class="feature-icon">
                <i class="lnr lnr-users font-40 color-theme"></i>
              </div>
              <h4 class="text-uppercase font-16">Community</h4>
            </div>
          </div>
          <!-- .col-md-6 end -->
          <div class="col-xs-12 col-sm-6 col-md-2 text-center-sm mb-30-sm">
            <div class="feature feature-2">
              <div class="feature-icon">
                <i class="lnr lnr-cloud-sync font-40 color-theme"></i>
              </div>
              <h4 class="text-uppercase font-16">Sustainability</h4>
            </div>
          </div>
          <!-- .col-md-6 end -->
          <div class="col-xs-12 col-sm-6 col-md-2 text-center-sm mb-30-sm">
            <div class="feature feature-2">
              <div class="feature-icon">
                <i class="lnr lnr-construction font-40 color-theme"></i>
              </div>
              <h4 class="text-uppercase font-16">Integrity</h4>
            </div>
          </div>
          <!-- .col-md-6 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Call To Action #3
============================================= -->
    <section
      id="cta-3"
      class="cta cta-3 text-center"
      style="padding-bottom: 0px !important"
    >
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
            <h2>Quality Comes First</h2>
            <p>
              Cutting-edge construction quality management system LATISTA
              ensures high quality standards at all of the company’s sites. The
              control mechanism allows integrated monitoring of works at all
              stages of construction and includes over 100 quality assessment
              benchmarks.
            </p>
            <div class="signiture">
              <img src="assets/images/call/sign.png" alt="signiture" />
            </div>
          </div>
          <!-- .col-md-8 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>
    <!-- #cta-3 end -->

    <!-- Shortcode #10 
============================================= -->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="heading heading-4">
                  <div class="heading-bg heading-right">
                    <p class="mb-0">Awesome Features</p>
                    <h2>Why Choose Us</h2>
                  </div>
                </div>
                <!-- .heading end -->
              </div>
            </div>
          </div>
          <!-- .col-md-12 end -->
          <div class="col-xs-12 col-sm-12 col-md-6">
            <div
              class="panel-group accordion"
              id="accordion02"
              role="tablist"
              aria-multiselectable="true"
            >
              <!-- Panel 01 -->
              <div
                class="panel panel-default"
                v-for="(f, index) in Faq"
                :key="f.id"
              >
                <div class="panel-heading" role="tab" :id="'heading' + index">
                  <h4 class="panel-title">
                    <a
                      class="accordion-toggle"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion02"
                      :href="'#collapse02-' + index"
                      aria-expanded="true"
                      :aria-controls="'collapse02-' + index"
                    >
                      {{ f.question }}
                    </a>
                    <span class="icon"></span>
                  </h4>
                </div>
                <div
                  :id="'collapse02-' + index"
                  class="panel-collapse collapse"
                  :class="index == 0 ? 'in' : ''"
                  role="tabpanel"
                  :aria-labelledby="'heading' + index"
                >
                  <div class="panel-body" v-html="f.answer"></div>
                </div>
              </div>

              <!-- Panel 02 -->
              <!-- <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwo">
                  <h4 class="panel-title">
                    <a
                      class="accordion-toggle collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion02"
                      href="#collapse02-2"
                      aria-expanded="false"
                      aria-controls="collapse02-2"
                    >
                      Our Mission
                    </a>
                  </h4>
                </div>
                <div
                  id="collapse02-2"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div class="panel-body">
                    We do only what we are great on. If we tackle a project you
                    can be 100% sure that it will be delivered right on time,
                    within the set budget limits and at the top level. We get
                    all our liabilities insured, including third-party
                    liabilities.
                  </div>
                </div>
              </div> -->

              <!-- Panel 03 -->
              <!-- <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingThree">
                  <h4 class="panel-title">
                    <a
                      class="accordion-toggle collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion02"
                      href="#collapse02-3"
                      aria-expanded="false"
                      aria-controls="collapse02-3"
                    >
                      Our Goals
                    </a>
                  </h4>
                </div>
                <div
                  id="collapse02-3"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingThree"
                >
                  <div class="panel-body">
                    Yellow Hats is a leading developer of A-grade commercial,
                    industrial and residential projects in USA. Since its
                    foundation the company has doubled its turnover year on
                    year, with its staff numbers swelling accordingly.
                  </div>
                </div>
              </div> -->
            </div>
            <!-- End .Accordion-->
          </div>
          <!-- .col-md-6 end -->
          <div class="col-xs-12 col-sm-12 col-md-6" v-if="images">
            <carousel
              :responsive="{
                0: { items: 1, nav: false },
                600: { items: 2, nav: false },
              }"
              stagePadding="15"
              autoplay="true"
            >
              <div
                v-for="i in images"
                :key="i.id"
                style="
                        width: 95%;
                        height: 30vh;
                        background-size: cover;
                        background-position: center center;
                        border-radius: 10px;
                            

}
                      "
                :style="
                  `background-image: url(${
                    $hostname + i.imageLink.replaceAll('\\', '/')
                  }` + ')'
                "
              ></div>
              <!-- <img
                style="border-radius: 10px; width: 100%"
                v-for="i in images"
                :key="i.id"
                :src="$hostname + i.imageLink.replaceAll('\\', '/')"
              /> -->

              <!-- <img src="https://placeimg.com/200/200/any?2" />
            <img src="https://placeimg.com/200/200/any?3" />
            <img src="https://placeimg.com/200/200/any?4" /> -->
            </carousel>
          </div>
          <!-- .col-md-6 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>
  </div>
</template>




<script>
import carousel from "vue-owl-carousel";
import $ from "jquery";
export default {
  name: "About",
  components: { carousel },

  data() {
    return {
      About: null,
      Certifications: null,
      Faq: null,
      images: null,
    };
  },
  methods: {
    cleanDate(date) {
      let thisDate = new Date(date);
      return thisDate.toLocaleDateString("en-GB");
    },
    getimages() {
      this.$loading(true);
      this.$Axios
        .get(`${this.$hostname}/api/Galleries`)
        .then((Response) => {
          this.images = Response.data;
          this.$loading(false);
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },
    getFaq() {
      this.$Axios
        .get(`${this.$hostname}/api/FAQs`)
        .then((Response) => {
          this.Faq = Response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCertifications() {
      this.$Axios
        .get(`${this.$hostname}/api/Certifications`)
        .then((Response) => {
          this.Certifications = Response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAbout() {
      this.$Axios
        .get(`${this.$hostname}/api/Recipes`)
        .then((Response) => {
          this.About = Response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getAbout();
    this.getCertifications();
    this.getFaq();
    this.getimages();

    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      $(".navbar-collapse").removeClass("in");
    }, 100);
  },
};
</script>

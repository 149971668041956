<template>
  <div>
    <!-- Page Title
============================================= -->
    <section
      class="bg-overlay bg-overlay-gradient pb-0"
      style="padding-top: 0px !important"
    >
    
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div
              class="page-title title-1 text-center"
              style="
                padding-top: 50px !important;
                padding-bottom: 50px !important;
              "
            >
              <div class="title-bg">
                <h2>Makan</h2>
              </div>
              <ol class="breadcrumb">
                <li>
                  <router-link to="/">Home</router-link>
                </li>
                <li class="active">Makan</li>
              </ol>
            </div>
          </div>
          <!-- .col-md-12 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Service Block #4
============================================= -->
    <section class="service service-4">
      <div class="container">
        <div class="row">
          <!-- .col-md-6 end -->

          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="row">
              <!-- Service Block #1 -->
              <div
                class="col-xs-12 col-sm-4 col-md-4 service-block"
                v-for="m in Makan"
                :key="m.id"
              >
              <router-link
                      class="read-more"
                      :to="`/SingleMakan/${m.id}`"
                    >
                <div
                  style="
                    width: 100%;
                    height: 30vh;
                    background-size: cover;
                    background-position: center center;
                  "
                  :style="
                    `background-image: url(${
                      $hostname + m.imageLink.replaceAll('\\', '/')
                    }` + ')'
                  "
                ></div>
              </router-link>
                <br />
                <div class="service-content">
                  <div class="service-desc">
                    <h4>{{ m.title }}</h4>
                    <p v-html="m.shortDescription"></p>
                    <router-link class="read-more" :to="`/SingleMakan/${m.id}`"
                      ><i class="fa fa-plus"></i>
                      <span>read more</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- .col-md-12 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Call To Action #1
============================================= -->
    
    <br />
  </div>
</template>


<script>
import $ from "jquery";
export default {
  name: "Makan",

  data() {
    return {
      Makan: null,
    };
  },
  methods: {
    cleanDate(date) {
      let thisDate = new Date(date);
      return thisDate.toLocaleDateString("en-GB");
    },
    getMakan() {
      this.$loading(true);

      this.$Axios
        .get(`${this.$hostname}/api/Blogs`)
        .then((Response) => {
          this.Makan = Response.data;
          this.$loading(false);
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },
  },
  mounted() {
    this.getMakan();
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      $(".navbar-collapse").removeClass("in");
    }, 100);
  },
};
</script>
<template>
  <div>
    <section
      class="bg-overlay bg-overlay-gradient pb-0"
      style="padding-top: 0px !important"
    >
      
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div
              class="page-title title-1 text-center"
              style="
                padding-top: 50px !important;
                padding-bottom: 50px !important;
              "
            >
              <div class="title-bg">
                <h2>Our Projects</h2>
              </div>
              <ol class="breadcrumb">
                <li>
                  <router-link to="/">Home</router-link>
                </li>
                <li class="active">Projects</li>
              </ol>
            </div>
            <!-- .page-title end -->
          </div>
          <!-- .col-md-12 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Projects Section
============================================= -->
    <section id="projects" class="projects-fullwidth projects-4col">
      <div class="container-fluid">
        <!-- .row end -->

        <!-- Projects Item
		============================================= -->
        <div id="projects-all" class="row">
          <!-- Project Item #1 -->
          <div
            class="col-xs-12 col-sm-6 col-md-4 project-item interior gardening"
            v-for="p in projects"
            :key="p.id"
          >
            <div class="project-img">
              <router-link :to="`/SingleProject/${p.id}`">
              <div
                style="
                  width: 100%;
                  height: 40vh;
                  background-size: cover;
                  background-position: center center;
                "
                :style="
                  `background-image: url(${
                    $hostname + p.imageLink.replaceAll('\\', '/')
                  }` + ')'
                "
              >
              </div>
           
              <div class="project-hover">
                <div class="project-meta">
                  <h6>{{ p.title }}</h6>
                  <h4>
                    <router-link :to="`/SingleProject/${p.id}`">{{
                      p.shortDescription
                    }}</router-link>
                  </h4>
                </div>
              </div>
            </router-link>
              <!-- .project-hover end -->
            </div>
            <!-- .project-img end -->
          </div>
          <!-- .project-item end -->

          <!-- .project-item end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Call To Action #1
============================================= -->
   
    <br />
    <!-- #cta-1 end -->
  </div>
</template>


<script>
import $ from "jquery";
export default {
  name: "Project",

  data() {
    return {
      projects: null,
    };
  },
  methods: {
    cleanDate(date) {
      let thisDate = new Date(date);
      return thisDate.toLocaleDateString("en-GB");
    },
    getprojects() {
      this.$loading(true);

      this.$Axios
        .get(`${this.$hostname}/api/Announcements`)
        .then((Response) => {
          this.projects = Response.data;
          this.$loading(false);
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      $(".navbar-collapse").removeClass("in");
    }, 100);
    this.getprojects();
  },
};
</script>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        count: 15
    },
    mutations: {
        increment(state) {
            state.count++
        }
    },
    getters: {
        getCount: state => {
            return state.count
        },
    }

})
<style scoped></style>
<template>
  <div>
    <header id="navbar-spy" class="transparent-header">
      <nav
        id="primary-menu"
        class="navbar navbar-fixed-top style-1"
        style="background-color: #333; border-bottom: solid #ffc527 1px"
      >
        <div class="container">
          <!-- Brand and toggle get grouped for better mobile display -->
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-expanded="false"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <router-link class="logo" to="/">
              <div style="display: flex">
                <div>
                  <img
                    :src="$hostname + '/Images/footer-logo.png'"
                    alt="Xera House"
                    style="width: 80px"
                  />
                </div>
                <div style="margin-top: 20px; ">
                  <h5 style="font-weight: 500">XERA HOUSE</h5>
                  <div style="margin-top: -55px;"><small>Consultant Engineering Bureau</small></div>
                </div>
              </div>
            </router-link>
          </div>

          <!-- Collect the nav links, forms, and other content for toggling -->
          <div
            class="collapse navbar-collapse pull-right"
            id="bs-example-navbar-collapse-1"
          >
            <ul class="nav navbar-nav navbar-left">
              <li class="has-dropdown">
                <router-link to="/">Home</router-link>
              </li>
              <!-- li end -->
              <li class="has-dropdown">
                <router-link to="/About" class="dropdown-toggle">about</router-link>
              </li>
              <!-- li end -->
              <li class="has-dropdown">
                <router-link to="/services" class="dropdown-toggle">services</router-link>
              </li>
              <!-- li end -->
              <li class="has-dropdown">
                <router-link to="/Projects" class="dropdown-toggle">projects</router-link>
              </li>
              <!-- li end -->
              <!-- li end -->
              <li class="has-dropdown">
                <router-link to="/Makan" class="dropdown-toggle">Makan</router-link>
              </li>
              <!-- li end -->
              <li class="has-dropdown pull-left">
                <router-link to="/contact" data-toggle="dropdown">contact</router-link>
              </li>
              <!-- li end -->
            </ul>
          </div>
          <!-- /.navbar-collapse -->
        </div>
        <!-- /.container-fluid -->
      </nav>
    </header>
  </div>
</template>
<script>
import $ from "jquery";
// import $ from "jquery";
export default {
  name: "LocaleSwitcher",
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      console.log("locale change", newVal, oldVal);
    },
    $route: function () {
      this.show = false;
    },
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    theLang(x) {
      if (x == "ku") {
        return "كوردی";
      } else if (x == "ar") {
        return "عربي";
      } else if (x == "en") {
        return "English";
      }
    },

    switchLocale(locale) {
      this.$loading(true);

      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.$session.set("Lang", locale);
        this.$Lang = locale;

        localStorage.setItem("theLang", locale);
      }

      this.$store.state.count = locale;
    },
  },
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),

      show: false,

      reloaded: false,
    };
  },
  mounted() {
    $("mobile-responsive-menu mean-container").removeClass("in");
    $("mobile-responsive-menu mean-container").addClass("collapse");
    //   if (localStorage.getItem("theLang")) {
    //     this.switchLocale(localStorage.getItem("theLang"));
    //   } else {
    //     let lang = this.$i18n.locale;
    //     this.switchLocale(lang);
    //   }

    //   setTimeout(() => {
    //     console.log(this.$Lang);
    //   }, 1000);
  },
};
</script>

<style >
  .modal-dialog {
    left: 0;
  }
  
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }
  @media screen and (max-width: 600px) {
    .modal-dialog {
      width: 100%;
  }
}
  
  .modal.fade .modal-dialog {
    transform: translate(0, -100%);
  }
  
  .modal.in .modal-dialog {
    transform: translate(0, 0);
  }
  </style>
<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="myModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
    <div class="modal-dialog modal-center">
      <div class="modal-content">
        <div class="" v-if="imagean">
          <!-- <img :src="selectedImage" style="width: 100%; height: auto" alt="" /> -->
          <carousel
            :dots="false"
            style="position: relative"
            data-toggle="modal"
            href="#myModal"
            v-if="imagean"
            :responsive="{
              0: { items: 1, nav: false },
              600: { items: 1, nav: false },
            }"
          >
            <div
              @click="selectedImage = $hostname + i.imageLink.replaceAll('\\', '/')"
              v-for="i in imagean"
              :key="i.id"
              style="
                  width: 100%;
                  height: 50vh;
                  background-size: contain;
                  background-position: center center;
                  background-repeat:no-repeat;
                  background-color:#2e2e2e
                  
}
                "
              :style="
                `background-image: url(${
                  $hostname + i.imageLink.replaceAll('\\', '/')
                }` + ')'
              "
            ></div>
          </carousel>
          <div
            style="
              position: absolute;
              top: 20px;
              right: 10px;
              border-radius: 10px;
              z-index: 100000000;
            "
          >
            <button
              style="display: block; margin: auto"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span style="color: white; font-weight: bold">X</span>
            </button>
          </div>
        </div>
        <!-- <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
      </div>
      <!-- /.modal-content -->
    </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
    <!-- Page Title
============================================= -->
    <section class="bg-overlay bg-overlay-gradient pb-0">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div
              class="page-title title-1 text-center"
              style="
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              "
            >
              <ol class="breadcrumb">
                <li>
                  <router-link to="/">Home</router-link>
                </li>
                <li>
                  <router-link to="/projects">Projects</router-link>
                </li>
                <li class="active">Single Project</li>
              </ol>
            </div>
            <!-- .page-title end -->
          </div>
          <!-- .col-md-12 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Single Project
============================================= -->
    <section class="single-project pb-0">
      <div class="container">
        <div class="row" v-if="projects">
          <div class="col-xs-12 col-sm-12 col-md-4">
            <div class="project-widget project-block">
              <div class="project-title">
                <h3>Project Description</h3>
              </div>
              <div class="project-desc">
                <ul class="list-unstyled">
                  <li>
                    Location:
                    <span>{{ projects.location }}</span>
                  </li>
                  <li>
                    Gross Building Area:
                    <span>{{ projects.area }}</span>
                  </li>

                  <li>
                    Category:
                    <span>{{ projects.category }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- .project-block end -->
            <!-- <div class="project-widget">
					<div class="project-title">
						<h3>Service Overview</h3>
					</div>
					<div class="project-desc">
						<p>When it comes to choosing a renovator to transfor the interior of your home, quality and trust should never be compromised. Working with a professional is an absolute must. With over 15 years experience and a real focus on customer satisfaction, you can rely on us for your next renovation</p>
						<p>Renovate has proven results for setting exceptional standards in cost control, planning, scheduling and project safety. We have experience that gives us a competitive advantage over others in our field.</p>
					</div>
				</div> -->
            <!-- .project-widget end -->

            <!-- <div class="project-widget">
					<div class="project-title">
						<h3>Project Management</h3>
					</div>
					<div class="project-desc">
						<p>When it comes to choosing a renovator to transfor the interior of your home, quality and trust should never be compromised. Working with a professional is an absolute must. With over 15 years experience and a real focus on customer satisfaction, you can rely on us for your next renovation, driveway sett on home repair. Our installations are carried out by fully trained staff to the highest professional standards.</p>
					</div>
				</div> -->
            <!-- .project-widget end -->
          </div>
          <!-- .col-md-4 end -->
          <div class="col-xs-12 col-sm-12 col-md-8">
            <div id="project-carousel" class="project-carousel mb-30">
              <div
                style="
                  width: 100%;
                  height: 40vh;
                  background-size: cover;
                  background-position: center center;
                "
                :style="
                  `background-image: url(${
                    $hostname + projects.imageLink.replaceAll('\\', '/')
                  }` + ')'
                "
              ></div>
            </div>
            <!-- .project-carousel end -->
            <div class="container__img-holder">
              <carousel
                data-toggle="modal"
                href="#myModal"
                v-if="imagean"
                :responsive="{
                  0: { items: 1, nav: false },
                  600: { items: 2, nav: false },
                }"
                stagePadding="15"
                autoplay="true"
              >
                <div
                  @click="
                    selectedImage =
                      $hostname + i.imageLink.replaceAll('\\', '/')
                  "
                  v-for="i in imagean"
                  :key="i.id"
                  style="
                        width: 100%;
                        height: 40vh;
                        background-size: cover;
                        background-position: center center;
                        border-radius: 10px;
                            

}
                      "
                  :style="
                    `background-image: url(${
                      $hostname + i.imageLink.replaceAll('\\', '/')
                    }` + ')'
                  "
                ></div>
                <!-- <img
                style="border-radius: 10px; width: 100%"
                v-for="i in images"
                :key="i.id"
                :src="$hostname + i.imageLink.replaceAll('\\', '/')"
              /> -->

                <!-- <img src="https://placeimg.com/200/200/any?2" />
            <img src="https://placeimg.com/200/200/any?3" />
            <img src="https://placeimg.com/200/200/any?4" /> -->
              </carousel>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="project-widget">
                  <div class="project-title">
                    <h3>{{ projects.title }}</h3>
                  </div>
                  <div class="project-desc">
                    <p v-html="projects.description"></p>
                  </div>
                </div>
                <!-- .project-widget end -->
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="project-widget">
                  <div class="project-title">
                    <h3>{{ projects.title2 }}</h3>
                  </div>
                  <div class="project-desc">
                    <p v-html="projects.body"></p>
                  </div>
                </div>
                <!-- .project-widget end -->
              </div>
            </div>
            <!-- .row end -->
          </div>
          <!-- .col-md-8 end -->
          <!-- 			
			<div class="col-xs-12 col-sm-12 col-md-12 pager-2">
				<div class="page-prev">
					<a href="#"><i class="fa fa-angle-left"></i>
						<span>Previous Project</span>
					</a>
				</div>
				<div class="page-next">
					<a href="#">
						<span>NExt Project</span>
						<i class="fa fa-angle-right"></i></a>
				</div>
			</div> -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Call To Action #1
============================================= -->
  
    <!-- #cta-1 end -->
  </div>
</template>


<script>
import $ from "jquery";
import carousel from "vue-owl-carousel";

export default {
  name: "Project",
  components: { carousel },

  data() {
    return {
      projects: null,
      imagean: null,
      selectedImage: null,
    };
  },
  watch:{
  $route (to, from){
    console.log(to,from);
    this.getimagean();
  }
},
  methods: {
    cleanDate(date) {
      let thisDate = new Date(date);
      return thisDate.toLocaleDateString("en-GB");
    },
    getimagean() {
      this.$loading(true);
      this.$Axios
        .get(
          `${this.$hostname}/getannouncementsextraimages/${this.$route.params.id}`
        )
        .then((Response) => {
          this.imagean = Response.data;
          this.$loading(false);
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },
    getprojects() {
      this.$Axios
        .get(`${this.$hostname}/api/Announcements/${this.$route.params.id}`)
        .then((Response) => {
          this.projects = Response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
    
    this.getprojects();
    this.getimagean();

    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      $(".navbar-collapse").removeClass("in");
    }, 100);
  },
};
</script>
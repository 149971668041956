import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import VueLoading from 'vuejs-loading-plugin'

import VueCarousel from 'vue-carousel';
import axios from 'axios';
import VueSimpleAlert from "vue-simple-alert";
import vmodal from 'vue-js-modal';
import VueSession from 'vue-session'
import { store } from './store'
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);
Vue.use(VueSession)
Vue.use(vmodal);
Vue.use(VueSimpleAlert);
Vue.use(VueCarousel);

Vue.prototype.$hostname = "https://xera.house";
//Vue.prototype.$hostname = "https://xera.house";
Vue.prototype.$Headers = {
    Headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",

    }
};
Vue.prototype.$Axios = axios;
//Vue.prototype.$Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
Vue.prototype.$Lang = "ku";
Vue.prototype.bus = new Vue();
Vue.use(VueLoading, {
    dark: true, // default false
    text: "Loading", // default 'Loading'
    background: "rgba(255,255,255,0.5)" // set custom background
});
Vue.config.productionTip = false




new Vue({
    created() {
        AOS.init();
    },
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
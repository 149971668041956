<style>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffc527; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffc527; 
}
</style>

<template>

  <div class="wrapper">
    
    <locale-switcher/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>
 <script src="./assets/js/jquery.min.js"></script>
    <!-- Plugins JS -->
    <script src="./assets/js/plugins.js"></script>
    <!-- Custom  JS -->
    <script src="./assets/js/custom.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>
<script>
import LocaleSwitcher from './components/Navigation/LocaleSwitcher.vue'
import Footer from './components/Navigation/Footer.vue'

export default {
  name: 'App',
  components: {
    LocaleSwitcher,
    Footer
  }
}
</script>

<style>

</style>

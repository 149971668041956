<template>
  <div>
    <!-- Page Title
============================================= -->
    <section
      class="bg-overlay bg-overlay-gradient pb-0"
      style="padding-top: 0px !important"
    >
       
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div
              class="page-title title-1 text-center"
              style="
                padding-top: 50px !important;
                padding-bottom: 50px !important;
              "
            >
              <div class="title-bg">
                <h2>contact us</h2>
              </div>
              <ol class="breadcrumb">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li class="active">contact</li>
              </ol>
            </div>
            <!-- .page-title end -->
          </div>
          <!-- .col-md-12 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Contact #1
============================================= -->
    <section id="contact" class="contact">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="heading heading-4">
              <div class="heading-bg heading-right">
                <p class="mb-0">We Wanna Hear From You !</p>
                <h2>Contact Us</h2>
              </div>
            </div>
            <!-- .heading end -->
          </div>
          <!-- .col-md-12 end -->
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="row">
              <div
                class="col-xs-12 col-sm-12 col-md-4 widgets-contact mb-60-xs"
              >
                <div class="widget">
                  <div class="widget-contact-icon pull-left">
                    <i class="lnr lnr-map"></i>
                  </div>
                  <div class="widget-contact-info">
                    <p class="text-capitalize">visit us</p>
                    <!-- <p class="text-capitalize font-heading">
                      tanta , alGharbia, egypt. </p> -->
                    <p v-if="contactt && contactt[2]">{{ contactt[2].data }}</p>
                  </div>
                </div>
                <!-- .widget end -->
                <div class="clearfix"></div>
                <div class="widget">
                  <div class="widget-contact-icon pull-left">
                    <i class="lnr lnr-envelope"></i>
                  </div>
                  <div class="widget-contact-info">
                    <p class="text-capitalize">email us</p>
                    <p v-if="contactt && contactt[0]">
                      <a
                        style="text-decoration: auto"
                        :href="'mailto:' + contactt[1].data"
                        >{{ contactt[1].data }}</a
                      >
                    </p>
                  </div>
                </div>
                <!-- .widget end -->
                <div class="clearfix"></div>
                <div class="widget">
                  <div class="widget-contact-icon pull-left">
                    <i class="lnr lnr-phone"></i>
                  </div>
                  <div class="widget-contact-info">
                    <p class="text-capitalize">call us</p>

                    <p v-if="contactt && contactt[1]">
                      <a
                        style="text-decoration: auto"
                        :href="'tel:' + contactt[0].data"
                        >{{ contactt[0].data }}</a
                      >
                    </p>
                  </div>
                </div>
                <!-- .widget end -->
              </div>
              <!-- .col-md-4 end -->
              <div class="col-xs-12 col-sm-12 col-md-8">
                <div class="row">
                  <form
                    id="contactForm"
                    @submit.prevent="postContact"
                    action="assets/php/sendmail.php"
                    method="post"
                  >
                    <div class="col-md-6">
                      <input
                        type="text"
                        v-model="ContactBody.name"
                        class="form-control mb-30"
                        name="contact-name"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        v-model="ContactBody.email"
                        type="email"
                        class="form-control mb-30"
                        name="contact-email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                    <div class="col-md-12">
                      <input
                        v-model="ContactBody.phoneNum"
                        type="text"
                        class="form-control mb-30"
                        name="contact-telephone"
                        id="telephone"
                        placeholder="Telephone"
                        required
                      />
                    </div>

                    <div class="col-md-12">
                      <textarea
                        v-model="ContactBody.message"
                        class="form-control mb-30"
                        name="contact-message"
                        id="message"
                        rows="2"
                        placeholder="Message Details"
                        required
                      ></textarea>
                    </div>
                    <div class="col-md-12">
                      <button
                        type="submit"
                        id="submit-button"
                        value="Send Message"
                        class="btn btn-primary btn-black btn-block"
                      >
                        Send Message
                      </button>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 mt-xs">
                      <!--Alert Message-->
                      <div id="contact-result"></div>
                    </div>
                  </form>
                </div>
              </div>
              <!-- .col-md-8 end -->
            </div>
            <!-- .row end -->
          </div>
          <!-- .col-md-12 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Google Maps
============================================= -->
    <section class="google-maps pb-0 pt-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 pr-0 pl-0">
            <div id="googleMap" style="width: 100%; height: 240px"></div>
          </div>
        </div>
      </div>
    </section>
    <!-- .google-maps end -->
  </div>
</template>


<script>
import $ from "jquery";
export default {
  name: "Contact",
  data() {
    return {
      contactt: null,
      Contact: null,
      ContactBody: {
        name: null,
        email: null,
        phoneNum: null,
        message: null,
      },
      HeaderInfo: {
        phone: null,
        address: null,
      },
    };
  },
  methods: {
    getcontactt() {
      this.$loading(true);

      this.$Axios
        .get(`${this.$hostname}/api/Contacts`)
        .then((Response) => {
          this.contactt = Response.data;
          this.$loading(false);
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },
    postContact() {
      this.$loading(true);
      this.$Axios
        .post(`${this.$hostname}/api/ContactForms`, this.ContactBody)
        .then(() => {
          this.ContactBody = {
            name: null,
            email: null,
            phone: null,
            phoneNum: null,
          };
          this.$loading(false);
          this.$alert(
            "Your Message has been sent successfully",
            "Success",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },
  },
  mounted() {
    this.getcontactt();
    this.$Axios.get(this.$hostname + "/api/Contacts").then((res) => {
      this.HeaderInfo.phone = res.data[0].contactNumber;
      this.HeaderInfo.address = res.data[0].address;
    });
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      $(".navbar-collapse").removeClass("in");
    }, 100);
  },
};
</script>


<style>
.bg-section {
  height: 30vh;
}
</style>
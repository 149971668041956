<template>
  <div>
    <!-- Hero Section
====================================== -->
    <section id="hero" class="hero">
      <!-- START REVOLUTION SLIDER 5.0 -->
      <div id="myCarousel" class="carousel slide" data-ride="carousel">
        <!-- Indicators -->
        <ol class="carousel-indicators">
          <li
            style="margin-right: 10px"
            v-for="(s, index) in Imageslider"
            :key="'dots' + s.id"
            data-target="#myCarousel"
            :data-slide-to="index"
            :class="index == 0 ? 'active' : ''"
          ></li>
        </ol>

        <!-- Wrapper for slides -->
        <div class="carousel-inner">
          <div
            :class="index == 0 ? 'active' : ''"
            class="item"
            v-for="(s, index) in Imageslider"
            :key="s.id"
          >
            <!-- <img :src="$hostname+ s.imageLink.replaceAll('\\','/')" alt="Los Angeles" style="width:100%;"> -->
            <div
              style="
                width: 100%;
                height: 100vh;
                background-size: cover;
                background-position: center center;
              "
              :style="
                `background-image: url(${
                  $hostname + s.imageLink.replaceAll('\\', '/')
                }` + ')'
              "
            ></div>
            <div
              class="carousel-caption"
              style="background-color: rgba(0, 0, 0, 0.6); border-radius: 10px"
            >
              <h3
                style="
                  color: white;
                  text-transform: uppercase;
                  font-size: 32px;
                  line-height: 30px;
                  margin: 0 0 20px 0;
                "
              >
                {{ s.title }}
              </h3>
              <p
                v-html="s.description"
                style="
                  font-size: 17px;
                  font-family: 'Montserrat';
                  font-weight: 100;
                "
              ></p>
            </div>
          </div>

          <!-- <div class="item">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png" alt="Chicago" style="width:100%;">
        <div class="carousel-caption">
          <h3>Chicago</h3>
          <p>Thank you, Chicago!</p>
        </div>
      </div> -->

          <!-- <div class="item">
        <img src="ny.jpg" alt="New York" style="width:100%;">
        <div class="carousel-caption">
          <h3>New York</h3>
          <p>We love the Big Apple!</p>
        </div>
      </div> -->
        </div>

        <!-- Left and right controls -->
        <a class="left carousel-control" href="#myCarousel" data-slide="prev">
          <span class="glyphicon glyphicon-chevron-left"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="right carousel-control" href="#myCarousel" data-slide="next">
          <span class="glyphicon glyphicon-chevron-right"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <!-- END OF SLIDER WRAPPER -->
    </section>
    <!-- #hero end -->

    <!-- Shortcode #2 
============================================= -->
    <!-- <section
      id="shortcode-2"
      class="shortcode-2 about-home text-center-xs text-center-sm"
    >
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <div class="heading heading-1 mb-0">
                  <p>all about us</p>
                  <h2>our stroy</h2>
                </div>
              </div>
              <div
                class="col-xs-12 col-xs-12 col-md-12 mb-50"
                v-for="c in Certifications"
                :key="c.id"
              >
                <p v-html="c.description"></p>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="feature feature-2 mb-30-xs mb-30-sm">
                  <div class="feature-icon">
                    <i class="lnr lnr-license font-40 color-theme"></i>
                  </div>
                  <h4 class="text-uppercase font-16">safety</h4>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="feature feature-2 mb-30-xs mb-30-sm">
                  <div class="feature-icon">
                    <i class="lnr lnr-users font-40 color-theme"></i>
                  </div>
                  <h4 class="text-uppercase font-16">Community</h4>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="feature feature-2 mb-30-xs">
                  <div class="feature-icon">
                    <i class="lnr lnr-cloud-sync font-40 color-theme"></i>
                  </div>
                  <h4 class="text-uppercase font-16">Sustainability</h4>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="feature feature-2 mb-30-xs">
                  <div class="feature-icon">
                    <i class="lnr lnr-construction font-40 color-theme"></i>
                  </div>
                  <h4 class="text-uppercase font-16">Integrity</h4>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section> -->

    <!-- Service Block #2
============================================= -->
    <section id="service-2" class="service service-2 bg-gray pb-0">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
            <div class="heading heading-2 text-center mb-60">
              <div class="heading-bg">
                <p class="mb-0">CEO and Founder</p>
                <h2>Hawkar Aziz Mahmoud</h2>
              </div>
              <p class="mb-0 mt-md">
                We're happily settled in our comfort zone and staying there has
                became our habit, but we should remember that, success begins at
                the end of our comfort zone. So that one decision, which is
                uncomfortable to take , but at the same time is indispensable
                for our success will lead us toward the life we always wanted.
              </p>
            </div>
            <!-- .heading end -->
          </div>
          <!-- .col-md-6 end -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="row">
              <!-- Nav tabs -->
              <ul class="nav nav-tabs" role="tablist">
                <!-- Nav Tab #1 -->
                <li
                  @click="doscroll"
                  class="col-md-2"
                  role="presentation"
                  v-for="(w, index) in whatwecando"
                  :key="'w' + w.id"
                >
                  <a
                    :href="'#' + index"
                    :aria-controls="index"
                    role="tab"
                    data-toggle="tab"
                  >
                    <div class="service-icon">
                      <img
                        style="width: 55px; margin-bottom: -32px"
                        :src="$hostname + w.theLink.replaceAll('\\', '/')"
                        alt="icon"
                      />
                      <img
                        style="width: 85px; margin-top: 12px"
                        :src="$hostname + w.theLink.replaceAll('\\', '/')"
                        alt="icon"
                      />
                    </div>
                    {{ w.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- .col-md-12 end-->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
      <div class="container-fluid bg-theme">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="row">
              <!-- Tab panes -->
              <div class="tab-content">
                <!-- Panel #1 -->
                <div
                  :class="index == 0 ? 'active in' : ''"
                  role="tabpanel"
                  class="tab-pane fade"
                  :id="index"
                  v-for="(w, index) in whatwecando"
                  :key="'w2' + w.id"
                >
                  <div class="col-xs-12 col-sm-6 col-md-6 col-img">
                    <div
                      style="
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        background-position: center center;
                      "
                      :style="
                        `background-image: url(${
                          $hostname + w.imageLink.replaceAll('\\', '/')
                        }` + ')'
                      "
                    ></div>
                  </div>
                  <!-- .col-md-6 end -->
                  <div class="col-xs-12 col-sm-6 col-md-6 col-content">
                    <h3 style="color: black !important">{{ w.title }}</h3>
                    <p
                      v-html="w.description"
                      style="color: black !important"
                    ></p>
                  </div>
                  <!-- .col-md-6 end -->
                </div>
                <!-- .tab-pane end -->

                <!-- .tab-pane end -->
              </div>
              <!-- .tab-content end -->
            </div>
            <!-- .row end -->
          </div>
          <!-- .col-md-12 -->
        </div>
        <!-- .row end -->
      </div>
      <!-- .container-fluid end -->
    </section>

    <!-- Projects
============================================= -->
    <section id="projects" class="projects-grid projects-3col">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="heading heading-3 mb-0 text-center">
              <div class="heading-bg">
                <p class="mb-0">Great &amp; Awesome Works</p>
                <h2>Our Projects</h2>
              </div>
            </div>
            <!-- .heading end -->
          </div>
          <!-- .col-md-12 end -->
        </div>
        <br />
        <br />

        <!-- Projects Item
		============================================= -->
        <div id="projects-all" class="row">
          <!-- Project Item #1 -->
          <div
            class="col-xs-12 col-sm-6 col-md-4 project-item interior"
            v-for="p in Projects.slice(0, 6)"
            :key="p.id"
          >
            <div class="project-img">
              <img
                class=""
                :src="$hostname + p.imageLink.replaceAll('\\', '/')"
                alt="interior"
                style="width: 102%; height: 250px"
              />
              <div class="project-hover">
                <div class="project-meta">
                  <h6>{{ p.title }}</h6>
                  <h4>
                    <router-link :to="`/SingleProject/${p.id}`">{{
                      p.shortDescription
                    }}</router-link>
                  </h4>
                </div>
              </div>
              <!-- .project-hover end -->
            </div>
            <!-- .project-img end -->
          </div>
          <!-- .project-item end -->

          <!-- .project-item end -->
        </div>
        <!-- .row end -->

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 text-center">
            <router-link class="btn btn-secondary mt-sm" to="/Projects">
              more projects <i class="fa fa-plus"></i
            ></router-link>
          </div>
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </section>

    <!-- Shortcode #9 
============================================= -->
    <!-- <section id="clients" class="shortcode-9 pb-0">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="heading heading-2 text-center">
              <div class="heading-bg">
                <p class="mb-0">They Always Trust Us</p>
                <h2>Our Clients</h2>
              </div>
            </div>
           </div>
         </div>
         <div class="row">
           <div
            class="col-xs-12 col-sm-4 col-md-3"
            v-for="p in partners"
            :key="p.id"
          >
            <div class="brand">
              <a :href="p.link" target="_blank">
                <img
                  style="width: 100%; height: 160px"
                  class="img-responsive center-block"
                  :src="$hostname + p.imageLink.replaceAll('\\', '/')"
                  alt="brand"
                />
              </a>
            </div>
          </div>
 
         </div>
       </div>
     </section>
  -->
    <!-- Call To Action #1
============================================= -->
    <!-- <section id="cta-1" class="cta pb-0">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="cta-1 bg-theme">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-1 hidden-xs">
                  <div class="cta-img">
                    <img src="assets/images/call/cta-1.png" alt="cta" />
                  </div>
                </div>
                <div
                  class="
                    col-xs-12 col-sm-12 col-md-7
                    cta-devider
                    text-center-xs
                  "
                >
                  <div class="cta-desc">
                    <p>Have Any Questions !</p>
                    <h5>Don’t Hesitate To Contact Us ANy Time.</h5>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-sm-12 col-md-2 pull-right pull-none-xs"
                >
                  <div class="cta-action">
                    <router-link class="btn btn-secondary" to="/Contact"
                      >contact us</router-link
                    >
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <br />
  </div>
</template>


<script>
import $ from "jquery";

// import carousel from "vue-owl-carousel";
export default {
  name: "Home",
  //  components: { carousel },
  data() {
    return {
      Imageslider: null,
      Certifications: null,
      whatwecando: null,
      partners: null,
      Projects: null,
    };
  },
  methods: {
    cleanDate(date) {
      let thisDate = new Date(date);
      return thisDate.toLocaleDateString("en-GB");
    },
    getProjects() {
      this.$Axios
        .get(`${this.$hostname}/api/Announcements`)
        .then((Response) => {
          this.Projects = Response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    doscroll() {
      if (window.innerWidth < 600) {
        window.scrollTo({ top: 3000, left: 3000, behavior: "smooth" });
      } else {
        window.scrollTo({ top: 1750, left: 1750, behavior: "smooth" });
      }
    },
    getpartners() {
      this.$Axios
        .get(`${this.$hostname}/api/Partners`)
        .then((Response) => {
          this.partners = Response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getwhatwecando() {
      this.$Axios
        .get(`${this.$hostname}/api/WhatWeCanDoes`)
        .then((Response) => {
          this.whatwecando = Response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getImageslider() {
      this.$loading(true);

      this.$Axios
        .get(`${this.$hostname}/getMyHomeSliders`)
        .then((Response) => {
          this.Imageslider = Response.data;
          this.$loading(false);
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },
    getCertifications() {
      this.$Axios
        .get(`${this.$hostname}/api/Certifications`)
        .then((Response) => {
          this.Certifications = Response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getImageslider();
    this.getCertifications();
    this.getwhatwecando();
    this.getpartners();
    this.getProjects();
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      $(".navbar-collapse").removeClass("in");
    }, 100);
  },
};
</script>


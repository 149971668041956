import Vue from 'vue';
import Router from 'vue-router';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Services from './components/Pages/Services';
import Contact from './components/Pages/Contact';
import Makan from './components/Pages/Makan';
import SingleServices from './components/Pages/SingleServices';
import SingleMakan from './components/Pages/SingleMakan';
import Projects from './components/Pages/Projects';
import SingleProject from './components/Pages/SingleProject';










Vue.use(Router);

export default new Router({
    routes: [{
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/About',
            name: 'About',
            component: About
        },
        {
            path: '/Services',
            name: 'Services',
            component: Services
        },
        {
            path: '/Makan',
            name: 'Makan',
            component: Makan
        },
        {
            path: '/Projects',
            name: 'Projects',
            component: Projects
        },
        {
            path: '/Contact',
            name: 'Contact',
            component: Contact
        },
        {
            path: '/SingleServices/:id',
            name: 'SingleServices',
            component: SingleServices
        },
        {
            path: '/SingleMakan/:id',
            name: 'SingleMakan',
            component: SingleMakan
        },
        {
            path: '/SingleProject/:id',
            name: 'SingleProject',
            component: SingleProject
        },




    ]
})
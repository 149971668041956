<template>
  <div>
    <footer id="footer" class="footer-4">
      <!-- Widget Section
	============================================= -->
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 widgets-links">
            <div
              class="
                col-xs-12 col-sm-12 col-md-4
                widget-about
                text-center-xs
                mb-30-xs
              "
            >
              <div class="widget-about-logo pull-left pull-none-xs">
                <img
                  :src="$hostname + '/Images/footer-logo.png'"
                  alt="Xera House"
                  style="width: 75px"
                />
              </div>
              <div class="widget-about-info">
                <h5 class="text-capitalize text-white">Xera House</h5>
                <p class="mb-0">
                  We have a doubtless vision of being recognized as one of the
                  leading contacting companies in the region by playing an out-
                  standing role in architectural design and all engineering
                  services around our country, and to contribute to the
                  development of the communities residing within. We intend to
                  become the key player and leader in Iraq
                </p>
              </div>
            </div>
            <div
              class="
                col-xs-12 col-sm-6 col-md-3
                widget-navigation
                text-center-xs
                mb-30-xs
              "
            >
              <h5 class="text-capitalize text-white">Social Media</h5>
              <div class="row">
                <div class="col-xs-4 col-sm-4 col-md-4">
                  <ul class="list-unstyled text-capitalize" style="list-style:none;display:inline-flex">
                    <li v-for="s in social" :key="s.id" style="margin-right:20px">
                      <a :href="'http://' + s.link" target="_blank">
                        <i
                          class="fa-brands"
                          :class="`fa-${s.type}`"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="col-xs-12 col-sm-6 col-md-5 widget-services text-center-xs"
            >
              <h5 class="text-capitalize text-white">We Wanna Hear From You</h5>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                  <ul class="list-unstyled text-capitalize">
                    <li>
                      <div class="widget">
                        <div class="widget-contact-info">
                          <p v-if="contactt && contactt[2]">
                            <i
                              class="lnr lnr-map"
                              style="margin-right: 10px"
                            ></i>

                            {{ contactt[2].data }}
                          </p>
                        </div>
                        <div class="widget-contact-info">
                          <p v-if="contactt && contactt[3]">
                            <i
                              class="lnr lnr-map"
                              style="margin-right: 10px"
                            ></i>

                            {{ contactt[3].data }}
                          </p>
                        </div>
                        <div class="widget-contact-info">
                          <p v-if="contactt && contactt[4]">
                            <i
                              class="lnr lnr-map"
                              style="margin-right: 10px"
                            ></i>

                            {{ contactt[4].data }}
                          </p>
                        </div>
                        <div class="widget-contact-info">
                          <p v-if="contactt && contactt[5]">
                            <i
                              class="lnr lnr-map"
                              style="margin-right: 10px"
                            ></i>

                            {{ contactt[5].data }}
                          </p>
                        </div>
                      </div>
                    </li>
                   
                    <li>
                      <div class="widget">
                        <div class="widget-contact-icon pull-left"></div>
                        <div class="widget-contact-info">
                          <p v-if="contactt && contactt[0]">
                            <i
                              class="lnr lnr-envelope"
                              style="margin-right: 10px"
                            ></i>
                            <a
                              style="text-decoration: auto"
                              :href="'mailto:' + contactt[1].data"
                              >{{ contactt[1].data }}</a
                            >
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="widget">
                        <div class="widget-contact-info">
                          <p v-if="contactt && contactt[1]">
                            <i
                              class="lnr lnr-phone"
                              style="margin-right: 10px"
                            ></i>

                            <a
                              style="text-decoration: auto"
                              :href="'tel:' + contactt[0].data"
                              >{{ contactt[0].data }}</a
                            >
                          </p>
                        </div>
                      </div>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <!-- Copyrights
	============================================= -->
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 copyrights text-center">
            <p class="text-capitalize">
              Develop by
              <a style="color:white;font-weight: bold;" href="https://www.plustheedge.com/" target="_blank"
                >Plus the Edge</a
              >
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      contactt: null,
      social: null,
    };
  },
  methods: {
    getcontactt() {
      this.$Axios
        .get(`${this.$hostname}/api/Contacts`)
        .then((Response) => {
          this.contactt = Response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getsocial() {
      this.$Axios
        .get(`${this.$hostname}/api/Socials`)
        .then((Response) => {
          this.social = Response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getcontactt();
    this.getsocial();
  },
};
</script>

<style>
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('section',{staticClass:"shotcode-1 text-center-xs text-center-sm",attrs:{"id":"shotcode-1"}},[_c('div',{staticClass:"container"},_vm._l((_vm.About),function(a){return _c('div',{key:a.id,staticClass:"row"},[_vm._m(1,true),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[_c('h3',{staticClass:"color-heading mb-sm font-18"},[_vm._v(" "+_vm._s(a.title)+" ")]),_c('p',{staticClass:"mb-60",domProps:{"innerHTML":_vm._s(a.description)}})]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[_c('div',{staticStyle:{"width":"100%","height":"40vh","background-size":"cover","background-position":"center center"},style:(`background-image: url(${
                  _vm.$hostname + a.imageLink.replaceAll('\\', '/')
                }` + ')')})])])}),0)]),_c('section',{staticClass:"shortcode-6 bg-gray text-center-xs",attrs:{"id":"shortcode-6"}},[_c('div',{staticClass:"container"},[_vm._m(2),_c('div',{staticClass:"row"},[_vm._l((_vm.Certifications),function(c){return _c('div',{key:c.id,staticClass:"col-xs-12 col-xs-12 col-md-12 mb-50"},[_c('p',{domProps:{"innerHTML":_vm._s(c.description)}})])}),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)],2)])]),_vm._m(7),_c('section',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(8),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[_c('div',{staticClass:"panel-group accordion",attrs:{"id":"accordion02","role":"tablist","aria-multiselectable":"true"}},_vm._l((_vm.Faq),function(f,index){return _c('div',{key:f.id,staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-heading",attrs:{"role":"tab","id":'heading' + index}},[_c('h4',{staticClass:"panel-title"},[_c('a',{staticClass:"accordion-toggle",attrs:{"role":"button","data-toggle":"collapse","data-parent":"#accordion02","href":'#collapse02-' + index,"aria-expanded":"true","aria-controls":'collapse02-' + index}},[_vm._v(" "+_vm._s(f.question)+" ")]),_c('span',{staticClass:"icon"})])]),_c('div',{staticClass:"panel-collapse collapse",class:index == 0 ? 'in' : '',attrs:{"id":'collapse02-' + index,"role":"tabpanel","aria-labelledby":'heading' + index}},[_c('div',{staticClass:"panel-body",domProps:{"innerHTML":_vm._s(f.answer)}})])])}),0)]),(_vm.images)?_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[_c('carousel',{attrs:{"responsive":{
                0: { items: 1, nav: false },
                600: { items: 2, nav: false },
              },"stagePadding":"15","autoplay":"true"}},_vm._l((_vm.images),function(i){return _c('div',{key:i.id,staticStyle:{"width":"95%","height":"30vh","background-size":"cover","background-position":"center center","border-radius":"10px"},style:(`background-image: url(${
                    _vm.$hostname + i.imageLink.replaceAll('\\', '/')
                  }` + ')')})}),0)],1):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-overlay bg-overlay-gradient pb-0",staticStyle:{"padding-top":"0px !important"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"page-title title-1 text-center",staticStyle:{"padding-top":"50px !important","padding-bottom":"50px !important"}},[_c('div',{staticClass:"title-bg"},[_c('h2',[_vm._v("About Us")])]),_c('ol',{staticClass:"breadcrumb"},[_c('li',[_c('a',{attrs:{"href":"index.html"}},[_vm._v("Home")])]),_c('li',{staticClass:"active"},[_vm._v("About")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"heading heading-4"},[_c('div',{staticClass:"heading-bg heading-right"},[_c('p',{staticClass:"mb-0"},[_vm._v("All About Xera House")]),_c('h2',[_vm._v("Our Story")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"heading heading-4"},[_c('div',{staticClass:"heading-bg heading-right"},[_c('p',{staticClass:"mb-0"},[_vm._v("Internationally Trusted !")]),_c('h2',[_vm._v("Certifications")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12 col-sm-6 col-md-2 text-center-sm mb-30-sm"},[_c('div',{staticClass:"feature feature-2"},[_c('div',{staticClass:"feature-icon"},[_c('i',{staticClass:"lnr lnr-license font-40 color-theme"})]),_c('h4',{staticClass:"text-uppercase font-16"},[_vm._v("safety")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12 col-sm-6 col-md-2 text-center-sm mb-30-sm"},[_c('div',{staticClass:"feature feature-2"},[_c('div',{staticClass:"feature-icon"},[_c('i',{staticClass:"lnr lnr-users font-40 color-theme"})]),_c('h4',{staticClass:"text-uppercase font-16"},[_vm._v("Community")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12 col-sm-6 col-md-2 text-center-sm mb-30-sm"},[_c('div',{staticClass:"feature feature-2"},[_c('div',{staticClass:"feature-icon"},[_c('i',{staticClass:"lnr lnr-cloud-sync font-40 color-theme"})]),_c('h4',{staticClass:"text-uppercase font-16"},[_vm._v("Sustainability")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12 col-sm-6 col-md-2 text-center-sm mb-30-sm"},[_c('div',{staticClass:"feature feature-2"},[_c('div',{staticClass:"feature-icon"},[_c('i',{staticClass:"lnr lnr-construction font-40 color-theme"})]),_c('h4',{staticClass:"text-uppercase font-16"},[_vm._v("Integrity")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"cta cta-3 text-center",staticStyle:{"padding-bottom":"0px !important"},attrs:{"id":"cta-3"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-8 col-md-offset-2"},[_c('h2',[_vm._v("Quality Comes First")]),_c('p',[_vm._v(" Cutting-edge construction quality management system LATISTA ensures high quality standards at all of the company’s sites. The control mechanism allows integrated monitoring of works at all stages of construction and includes over 100 quality assessment benchmarks. ")]),_c('div',{staticClass:"signiture"},[_c('img',{attrs:{"src":"assets/images/call/sign.png","alt":"signiture"}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[_c('div',{staticClass:"heading heading-4"},[_c('div',{staticClass:"heading-bg heading-right"},[_c('p',{staticClass:"mb-0"},[_vm._v("Awesome Features")]),_c('h2',[_vm._v("Why Choose Us")])])])])])])
}]

export { render, staticRenderFns }